<template>
  <div>
    <FormulaBanner :formulaCode="formulaCode" :title="tableData.chineseName" />
    <div class="index">
      <div>
        <div class="top_nav"><span></span>{{ tableData.chineseName }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="updateUnits(currentUnit)">
            <div class="form_title">国际单位（推荐）</div>

            <div
              v-for="item in internationalUnits"
              :key="item"
              class="form_card"
            >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input
                v-model.number="form[item]"
                :placeholder="item"
                autocomplete="off"
                class="form_card_input_NEW"
                step="0.0001"
                type="number"
                @blur="setCurrentUnit(item)"
              />
            </div>

            <div class="form_title">常用单位</div>
            <div v-for="item in commonUnits" :key="item" class="form_card">
              <div class="form_card_label">
                {{ item }}
              </div>
              <input
                v-model.number="form[item]"
                :placeholder="item"
                autocomplete="off"
                class="form_card_input_NEW"
                step="0.0001"
                type="number"
                @blur="setCurrentUnit(item)"
              />
            </div>

            <div style="margin: 16px;display: flex;justify-content: center;">
              <van-button
                block
                native-type="submit"
                style="width:35%;margin-right: 15px"
                type="info"
                >计算</van-button
              >
              <van-button
                block
                style="width:35%;border-color: #0091FB;color: #0091FB"
                @click="backBtn"
                >重置
              </van-button>
            </div>
          </van-form>
        </div>
      </div>

      <div
        v-if="tableData.explain && tableData.explain.length > 0"
        style=" margin-top: 15px;"
      >
        <div
          v-for="(item, index) in tableData.explain"
          :key="index"
          class="StandardDescription"
        >
          <div class="top_nav"><span></span>{{ item.name || item.title }}</div>
          <div style="padding: 2px 10px;background: white">
            <span v-html="item.setText || item.value"> </span>
          </div>
        </div>
      </div>
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>
  </div>
</template>
<script>
import FormulaBanner from "@/components/FormulaBanner.vue";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import { getFormula } from "@/service/BodyMassIndex_api";
import { postDataBrowse } from "@/service/api";
import FormulaReferences from "@/components/template1/FormulaReferences";

export default {
  name: "untilTest",
  components: { FormulaBanner, FormulaReferences },
  data() {
    return {
      formulaCode: "",
      countText: "",
      form: {},
      tableData: {},
      internationalUnits: ["10^9/L", "g/L", "gpt/L", "cells/L"],
      commonUnits: [
        "10^3/μL",
        "10^3/mm^3",
        "K/μL",
        "K/mm^3",
        "cells/μL",
        "cells/mm^3",
      ],
      currentUnit: "10^9/L",
    };
  },
  mounted() {
    this.test();
    this.getTableData();
  },
  methods: {
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      const data = {
        type: "yxgs_dwhs", //index表示在首页列表中点击，mix_index表示在专题列表中点击
        contentId: Number(this.$route.query.id), //为vod时，根据contentId增加浏览量
        contentType: "YXGS_DWHS",
        location: this.$route.query.location,
        channel: this.$route.query.channel,
        os: arrays.os,
        memberId: this.$route.query.memberId,
      };
      await postDataBrowse(data);
      await setById(arrays);
    },
    setCurrentUnit(unit) {
      if (
        this.form[unit] === null ||
        this.form[unit] === undefined ||
        this.form[unit] === ""
      ) {
        return;
      }
      this.currentUnit = unit;
      this.updateUnits(unit);
    },
    updateUnits(unit) {
      const group1 = [
        "10^9/L",
        "g/L",
        "gpt/L",
        "10^3/μL",
        "10^3/mm^3",
        "K/μL",
        "K/mm^3",
      ];
      const group2 = ["cells/L"];
      const group3 = ["cells/μL", "cells/mm^3"];

      const value = parseFloat(this.form[unit]);
      if (group1.includes(unit)) {
        group1.forEach((item) => {
          this.form[item] = value;
        });
        group2.forEach((item) => {
          this.form[item] = this.applyPrecision(value * 1e9);
        });
        group3.forEach((item) => {
          this.form[item] = this.applyPrecision(value * 1e3);
        });
      } else if (group2.includes(unit)) {
        group1.forEach((item) => {
          this.form[item] = this.applyPrecision(value * (1 / 1e9));
        });
        group2.forEach((item) => {
          this.form[item] = value;
        });
        group3.forEach((item) => {
          this.form[item] = this.applyPrecision(value * (1 / 1e6));
        });
      } else if (group3.includes(unit)) {
        group1.forEach((item) => {
          this.form[item] = this.applyPrecision(value * 0.001);
        });
        group2.forEach((item) => {
          this.form[item] = this.applyPrecision(value * 1e6);
        });
        group3.forEach((item) => {
          this.form[item] = value;
        });
      }
    },

    applyPrecision(result) {
      // 转换为浮点数
      const floatValue = parseFloat(result);
      // 如果是浮点数且小数位数超过四位则保留四位小数，否则返回原始结果
      if (!isNaN(floatValue) && floatValue % 1 !== 0) {
        // 检查是否有小数
        const roundedValue = floatValue.toFixed(4); // 四舍五入保留四位小数
        return parseFloat(roundedValue).toString(); // 转回字符串
      }
      return floatValue.toString(); // 返回原始结果字符串
    },
    backBtn() {
      this.form = {};
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "UIRYDBSM",
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  min-height: 100vh;
  height: auto;
  padding-bottom: 20px;
  font-size: 16px;

  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;

    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }

  .formBody {
    background-color: #fff;
    padding: 10px;

    .form_title {
      padding: 5px 8px;
      background: #e8f5ff;
    }

    .form_card {
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      margin: 10px;
      align-items: center;

      .form_card_input {
        border-radius: 3px;
        min-height: 25px;
        border: 1px solid #e3e3e3;
      }
    }

    .CalculationResultsBox {
      padding: 0 8px;

      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
